<template>
  <section class="w-full bg-white">
    <div class="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 pl-4 pr-4 pt-4 pb-2 gap-4 lg:mb-0">
      <div class="field">
        <label for="icon">Fecha inicial</label>
        <input
          v-model="filters.fechaInicial"
          class="p-button-secondary w-full border p-2 rounded-md mb-1 mt-1 flex-items-center p-inputtext-xs"
          type="date">
      </div>
      <div class="field">
        <label for="icon">Fecha final</label>
        <input
          v-model="filters.fechaFinal"
          class="p-button-secondary w-full border p-2 rounded-md mb-1 mt-1 flex-items-center p-inputtext-xs"
          type="date">
      </div>
      <div class="field">
        <label>Bodega Origen</label>
        <Dropdown
          optionLabel="bodega"
          optionValue="WhsCode"
          v-model="filters.WhsCode"
          :options="bodegas"
          panelClass="text-xxs"
          @click="$h.xxsInput"
          @change="onChangeBodOrigen"
          placeholder="Seleccione una bodega origen"
          class="rounded-md border-gray-300 w-full p-inputtext-xxs mt-1"
          :filter="true"
        />
      </div>
      <div class="field">
        <label>Bodega Destino</label>
        <Dropdown
          optionLabel="bodega"
          optionValue="WhsCode"
          v-model="filters.ToWareHouseCode"
          :options="bodegas"
          panelClass="text-xxs"
          @click="$h.xxsInput"
          placeholder="Seleccione una bodega destino"
          class="rounded-md border-gray-300 w-full p-inputtext-xxs mt-1"
          :filter="true"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 pl-4 gap-4 pr-4 pb-4 lg:mb-0">
      <div class="field grid">
        <label>Número de documento</label>
        <Chips
          id="DocNums"
          :allowDuplicate="false"
          v-model="filters.DocNums"
          separator=","
          inputClass="p-inputtext-xs"
          class="text-xs p-inputtext-xs w-full"
          placeholder="N° documentos separados por ,"
        />
      </div>
    </div>
    <div class="flex justify-end p-4 gap-2">
      <Button
        label="Limpiar"
        icon="pi pi-times"
        class="p-button-xs p-button-secondary"
        @click="clearFilters"/>
      <Button
        label="Buscar"
        icon="pi pi-search"
        class="p-button-xs"
        @click="getPaginate"/>
    </div>
  </section>
  <div class="pt-6 bg-white p-4">
    <DataTable
      :value="enviosPendientes"
      class="p-datatable-sm text-xs"
      showGridlines
      dataKey="KeyLine"
      responsiveLayout="scroll"
      v-model:selection="selectedCustomers"
      @row-select="onRowSelect"
      @row-select-all="onRowSelectAll"
      ref="tableList"
      removableSort
    >
      <template #empty>
        No se encontraron envíos registrados.
      </template>
      <Column
        selectionMode="multiple"
        bodyStyle="text-align: center;justify-content: center;"
        headerStyle="text-align: center;justify-content: center;"
        style="width: 4rem"
      />
      <Column
        field="DocDate"
        header="Fecha"
        headerStyle="text-align: center;justify-content: center;"
        style="min-width:10rem"
        bodyStyle="text-align: center">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.DocDate }}
          </p>
        </template>
      </Column>
      <Column
        field="DocNum"
        header="Número Documento"
        headerStyle="text-align: center;justify-content: center;"
        style="min-width:10rem"
        bodyStyle="text-align: center">
        <template #body="{data}">
          <Button
            :label="`${data.DocNum}`"
            class="p-button-link text-black"
            style="font-size: 0.75rem"
            @click="openModalDetailPendiente(data)"
          />
        </template>
      </Column>
      <Column
        field="ObjType"
        header="Tipo Documento"
        headerStyle="text-align: center;justify-content: center;"
        style="min-width:10rem"
        bodyStyle="text-align: center">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.ObjType }}
          </p>
        </template>
      </Column>
      <Column
        field="WhsBodOrigen"
        header="Bod. Origen"
        headerStyle="text-align: center;justify-content: center;"
        style="min-width:10rem"
        bodyStyle="text-align: center">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.WhsNameOrigen }}
          </p>
        </template>
      </Column>
      <Column
        field="WhsCode"
        header="Bod. Destino"
        headerStyle="text-align: center;justify-content: center;"
        style="min-width:10rem"
        bodyStyle="text-align: center">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.WhsName }}
          </p>
        </template>
      </Column>
    </DataTable>
    <div class="pt-2 flex justify-end">
      <Button
        label="Gestionar Envío"
        icon="pi pi-send"
        class="p-button-raised p-button-success floating-button  p-button-rounded"
        @click="getEnviosMasivos"/>
    </div>
    <Paginator
      v-model:first="params.offset"
      :rows="params.limit"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[10,20,30,50]"
      :pageLinkSize="5"
      @page="onPage($event)"
    />
  </div>

  <!-- Modal de info envio pendiente (Entrada de mercancia o solicitud de traslado)-->
  <modalEntradaMercancia ref="openModalEntradaMercancia"/>
  <modalSolicitudTraslado ref="openModalSolicitudTraslado"/>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, reactive, ref } from 'vue'
import ControlEnvioService from '@/apps/pharmasan/compras/logistica/services/control.service'
import { menuCollapse } from '@/utils/collapseMenu'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'

export default {
  name: 'pendientes',
  components: {
    modalEntradaMercancia: defineAsyncComponent(() =>
      import('./components/infoModalEntradaMercancia.vue')
    ),
    modalSolicitudTraslado: defineAsyncComponent(() =>
      import('./components/infoModalSolicitudTraslado.vue')
    )
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const selectedCustomers = ref([])
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const totalRecords = ref(0)
    const pageLinkSize = ref(5)
    const openModalEntradaMercancia = ref()
    const openModalSolicitudTraslado = ref()
    const dataEnvioPendiente = ref([])
    const enviosPendientes = ref([])
    const bodegasAsignadas = ref([])
    const bodegas = ref([])
    const _ControlEnvioService = new ControlEnvioService()
    const filters = reactive({
      fechaFinal: null,
      fechaInicial: null,
      ToWareHouseCode: null,
      DocNums: [],
      WhsCode: null
    })
    const getBodegas = async () => {
      await _ControlEnvioService.getAllBodegas().then(({ data }) => {
        bodegas.value = data
      })
    }
    const clearFilters = () => {
      filters.ToWareHouseCode = null
      filters.DocNums = []
      filters.WhsCode = null
      selectedCustomers.value = []
      getPaginate()
    }
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        ToWareHouseCode: filters.ToWareHouseCode,
        DocNums: filters.DocNums,
        WhsCode: !filters.WhsCode ? bodegasAsignadas.value : [filters.WhsCode]
      }
    })
    const onChangeBodOrigen = ({ value }) => {
      filters.WhsCode = value
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getPaginate(params.value)
    }
    const getEnviosMasivos = () => {
      if (selectedCustomers.value.length === 0) {
        Swal.fire({
          title: '¡Atención!',
          text: 'Debe seleccionar al menos un envío para continuar.',
          icon: 'warning',
          confirmButtonText: 'Aceptar'
        })
      } else {
        const consultarDocumentos = selectedCustomers.value.map((x) => {
          return {
            DocNum: x.DocNum,
            typeDoc: x.typeDoc
          }
        })
        const payload = {
          DocNums: consultarDocumentos
        }
        store.commit('controlEnvioStorage/setSeleccionados', payload)
        router.push({ name: 'pharmasan.compras.logistica.control-envios.informacion-principal' })
      }
    }
    const onRowSelect = (event) => {
      if (!selectedCustomers.value.every((x) => x.WhsCode === event.data.WhsCode)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Los envíos seleccionados deben tener un solo destino.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        }).then((response) => {
          selectedCustomers.value = selectedCustomers.value.filter(x => x.DocEntry !== event.data.DocEntry)
        })
      }
      if (!selectedCustomers.value.every((x) => x.BodOrigen === event.data.BodOrigen)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Los envíos seleccionados deben tener un solo origen.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        }).then((response) => {
          selectedCustomers.value = selectedCustomers.value.filter(x => x.DocEntry !== event.data.DocEntry)
        })
      }
    }
    const onRowSelectAll = (event) => {
      if (!event.data.every((x) => x.WhsCode === event.data[0].WhsCode)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Los envíos seleccionados deben tener un solo destino.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        }).then((response) => {
          selectedCustomers.value = []
        })
      }
      if (!event.data.every((x) => x.BodOrigen === event.data[0].BodOrigen)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Los envíos seleccionados deben tener un solo origen.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        }).then((response) => {
          selectedCustomers.value = []
        })
      }
    }
    const getPaginate = async () => {
      if ([undefined, null, ''].includes(filters.fechaInicial) || [undefined, null, ''].includes(filters.fechaFinal)) {
        await Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Por favor seleccione una fecha inicial y una fecha final.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
      await _ControlEnvioService.getPaginatePendientes(params.value).then((response) => {
        enviosPendientes.value = response.data.data
        totalRecords.value = response.data.rows[0].countRows
      })
    }

    // Funcion para abrir el modal de detalle de envio pendiente
    const openModalDetailPendiente = async (dataPendiente) => {
      await _ControlEnvioService.getInfoModalEnvioPendiente(dataPendiente).then(({ data }) => {
        // Agregar typeDoc a data
        if (dataPendiente.typeDoc === '1') {
          openModalEntradaMercancia.value.openModal(data)
        } else {
          openModalSolicitudTraslado.value.openModal(data)
        }
      })
    }
    const getValidateUserWhsCode = async () => {
      const user = store.getters['auth/user']
      const query = {
        id: user.user_id,
        admin: user.admin
      }
      await _ControlEnvioService.getValidateUser(query).then(({ data }) => {
        bodegasAsignadas.value = [...new Set(data.map((x) => x.WhsCode))]
      })
    }
    onMounted(async () => {
      await getValidateUserWhsCode()
      menuCollapse()
      const now = dayjs()
      const lastMonth = now.subtract(1, 'month')
      filters.fechaInicial = lastMonth.format('YYYY-MM-DD')
      filters.fechaFinal = now.format('YYYY-MM-DD')
      await getPaginate()
      await getBodegas()
    })
    return {
      filters,
      totalRecords,
      enviosPendientes,
      getPaginate,
      clearFilters,
      bodegas,
      pageLinkSize,
      limit,
      page,
      offset,
      onPage,
      params,
      selectedCustomers,
      onRowSelect,
      onRowSelectAll,
      getEnviosMasivos,
      router,
      getValidateUserWhsCode,
      bodegasAsignadas,
      onChangeBodOrigen,
      openModalEntradaMercancia,
      openModalSolicitudTraslado,
      dataEnvioPendiente,
      openModalDetailPendiente
    }
  }
}
</script>
<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}

.p-chips-token {
  padding: 0.1rem 0.5rem;
  margin-right: 0.4rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

::v-deep(.p-chips-multiple-container) {
  padding: 0.1rem 0.6rem;
  max-height: 70px;
  overflow-y: auto;
}

.floating-button {
  position: fixed !important;
  bottom: 35px;
  right: 40px;
  z-index: 1050;
}
</style>
